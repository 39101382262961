<template>
  <div v-if="images.length" class="myselfMessageContent__images">
    <div v-if="images.length" class="first_image image_wrap" @click="openLightboxOnSlide(0)">
      <img :src="images[0].attributes.url" alt="">
    </div>
    <template v-if="images.length > 1">
      <template v-for="(image, index) in images">
        <div v-if="index !== 0"
             :key="index"
             @click="openLightboxOnSlide(index)"
             class="other_images image_wrap"
        >
          <img :src="image.attributes.url" alt="">
        </div>
      </template>
    </template>
    <FsLightbox
        :toggler="toggler"
        :sources="imageUrls"
        :slide="slide"
        type="image"
        :onClose="emitCloseEvent"
    />
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue";

export default {
  name: "chat-images",
  components: {FsLightbox},
  props: {
    images: Array
  },
  data() {
    return {
      toggler: false,
      slide: 1
    }
  },
  computed: {
    imageUrls() {
      let urls = []

      for (let i = 0; i < this.images.length; i++) {
        urls.push(this.images[i].attributes.url)
      }

      return urls
    }
  },
  methods: {
    openLightboxOnSlide(index) {
      this.slide = index + 1;
      this.toggler = !this.toggler;

      this.$root.$emit('chat-image-open');
    },
    emitCloseEvent() {
      this.$root.$emit('chat-image-close');
    }
  }
}
</script>

<style scoped lang="scss">
.myselfMessageContent__images {
  margin-top: 8px;
  overflow: hidden;
  text-align: right;
}

.image_wrap {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &.first_image {
    width: 297px;
    height: 260px;
  }

  &.other_images {
    display: inline-block;
    margin-top: 9px;
    width: 78px;
    height: 78px;

    &:last-child {
      margin-left: 12px;
    }
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    z-index: 0;
  }
}
</style>