export const OnlineUsers = {
    methods: {
        checkOnlineUser(userId) {
            for (let user of this.onlineUsers) {
                if (user.id === userId) {
                    return true;
                }
            }
            return false;
        }
    }
}