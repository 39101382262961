<template>
    <div class="dialogsAndSystemsContent" ref="dialogs">
        <template v-for="(message, key) in messages">
            <simple-message v-if="message.attributes.type_message === 'simple'" :key="key" :message="message" :attachments="attachments"></simple-message>
            <system-message v-else :key="key" :message="message"></system-message>
        </template>
    </div>
</template>

<script>
import simpleMessage from "@/components/tournaments/singleTournament/singleMatch/сhat/simpleMessage";
import systemMessage from "@/components/tournaments/singleTournament/singleMatch/сhat/systemMessage";

export default {
    name: "DialogsAndSystemsContent",
    components: {
        simpleMessage, systemMessage
    },
    props: ['messages', 'attachments'],
    methods: {
        scrollToDown() {
            this.$refs.dialogs.lastElementChild.scrollIntoView({block: "end", inline: "nearest"})
        }
    },
    watch: {
        messages: function(newValue, oldValue) {
            this.$nextTick(() => this.scrollToDown())
        }
    }
}

</script>

<style lang="scss" scoped>
.dialogsAndSystemsContent {
    padding: 0 16px 16px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

  > div:last-child {
    margin-bottom: 16px;
  }

    .dialogsAndSystemsContentTimeBlock {
        padding-bottom: 20px;
        padding-top: 20px;

        &__text {
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 17px;
            color: white;
            opacity: .6;
            font-size: 14px;
        }
    }

    .noticeUserInvited {
        margin-top: 2px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        &__participant {
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;

            &_first {
                color: #0A84FF;
            }

            &_second {
                color: #FF453A
            }
        }

        &__invite {
            margin-left: 5px;
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;
            color: white;
            opacity: .6;
        }

        &__time {
            margin-left: 16px;
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;
            color: white;
            opacity: .6;
        }
    }

    .strangerMessageBlock {
        margin-top: 4px;
        margin-bottom: 4px;
        width: 100%;
        align-self: flex-start;
        flex-direction: row;
        display: flex;
        align-items: flex-end;

        .strangerAvatarWrapper {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            overflow: hidden;

            &__image {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .strangerMessage {
            max-width: 60%;
            width: fit-content;
            padding: 11.5px 16px;
            margin-left: 8px;
            background-color: #3A3A3A;
            border-radius: 4px;
            word-wrap: break-word;

            &__text {
                color: white;
                font-family: 'SF UI Text', serif;
                font-size: 14px;
                line-height: 16.8px;
                font-weight: normal;
            }
        }

      ::v-deep .strangerTime {
            margin-left: 8px;

            &__text {
                color: white;
                line-height: 14.4px;
                font-size: 12px;
                font-weight: normal;
                opacity: .6;
            }
        }
    }

    .myselfMessageBlock {
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 4px;

        .myselfMessageTime {
            &__time {
                color: white;
                opacity: .6;
                font-family: 'SF UI Text', serif;
                font-size: 12px;
                line-height: 14.4px;
                font-weight: normal;
            }
        }

        .myselfMessageContent {
            max-width: 60%;
            width: fit-content;
            padding: 11.5px 16px;
            margin-left: 8px;
            background-color: #757575;
            border-radius: 4px;
            word-wrap: break-word;

            &__text {
                color: white;
                font-family: 'SF UI Text', serif;
                font-size: 14px;
                line-height: 16.8px;
                font-weight: normal;
            }
        }
    }

    &:hover {
        overflow-y: scroll;
        padding: 0 8px 0 16px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #515151;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #242424;
        border-radius: 6px;
        border: 3px solid #515151;
    }
}
</style>
