<template>
    <div class="noticeUserInvited">
        <span :class="userColorByTeam(message.attributes.user_id)" class="noticeUserInvited__participant">{{ getUserLogin() }}</span>
        <span class="noticeUserInvited__invite">{{ message.attributes.message }}</span>
        <span class="noticeUserInvited__time">{{ this.formatterTime(message.attributes.createdAt) }}</span>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from "moment";
import {includeFilteringByType} from "@/otherJS/include-filtering";

export default {
    name: "systemMessage",
    props: ['message'],
    methods: {
        ...mapActions([
            'getUsersFromIds',
            'GET_TOURNAMENT_BY_ID'
        ]),
        getUser() {
            let user;

            user = this.users.find(user => Number(user.id) === Number(this.message.attributes.user_id));
            if (user !== undefined) {
                return user;
            }
            user = includeFilteringByType(this.chatIncluded, 'users').find(user => Number(user.id) === Number(this.message.attributes.user_id));
            return user;
        },
        getUserLogin() {
            let user = this.getUser();

            if (user.attributes.role === 'admin') {
                return 'judge';
            }

            let login = user.attributes.login;
            if (login !== undefined) {
                return login;
            }

            login = user.attributes.email;
            if (login !== undefined) {
                return login;
            }

            login = user.attributes.phone;
            if (login !== undefined) {
                return login;
            }
        },
        formatterTime(time) {
            return moment(time).format('LT');
        },
        userColorByTeam(userId) {
            let color = null;

            this.users.forEach(function(user, key) {
                if (Number(user.id) === Number(userId)) {
                    color = key;
                }
            });

            if (color === 0) {
                return 'noticeUserInvited__participant_second'
            } else if (color === 1) {
                return 'noticeUserInvited__participant_first'
            } else {
                return 'noticeUserInvited__participant_judge'
            }
        }
    },
    computed: {
        ...mapGetters([
            'USER',
            'COMPOSITIONS',
            'usersFromIds',
            'chatIncluded',
            'MATCH',
            'TOURNAMENT'
        ]),
        users() {
            let users = [];
            if (this.MATCH.data.attributes.participantable_first_type === 'teams') {
                this.COMPOSITIONS.forEach(function(usersCompositions) {
                    for (let user of usersCompositions.players) {
                        users.push(user);
                    }
                });
            } else {
                this.COMPOSITIONS.forEach(function(user) {
                    users.push(user.participant);
                });
            }
            // for (let usersCompositions of this.COMPOSITIONS) {
            //     for (let user of usersCompositions.players) {
            //         users.push(user);
            //     }
            // }
            return users;
        },
    }
}
</script>

<style lang="scss" scoped>
.dialogsAndSystemsContent {
    padding: 0 16px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialogsAndSystemsContentTimeBlock {
        padding-bottom: 20px;
        padding-top: 20px;

        &__text {
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 17px;
            color: white;
            opacity: .6;
            font-size: 14px;
        }
    }

    .noticeUserInvited {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        &__participant {
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;

            &_first {
                color: #0A84FF;
            }

            &_second {
                color: #FF453A;
            }

            &_judge {
                color: #E2FF31;
            }
        }

        &__invite {
            margin-left: 5px;
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;
            color: white;
            opacity: .6;
        }

        &__time {
            margin-left: 16px;
            font-family: 'SF UI Text', serif;
            font-weight: normal;
            line-height: 14.4px;
            font-size: 12px;
            color: white;
            opacity: .6;
        }
    }

    .strangerMessageBlock {
        margin-top: 8px;
        width: 100%;
        align-self: flex-start;
        flex-direction: row;
        display: flex;
        align-items: flex-end;

        .strangerAvatarWrapper {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            overflow: hidden;

            &__image {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .strangerMessage {
            max-width: 60%;
            width: fit-content;
            padding: 11.5px 16px;
            margin-left: 8px;
            background-color: #3A3A3A;
            border-radius: 4px;
            word-wrap: break-word;

            &__text {
                color: white;
                font-family: 'SF UI Text', serif;
                font-size: 14px;
                line-height: 16.8px;
                font-weight: normal;
            }
        }

        .strangerTime {
            margin-left: 8px;

            &__text {
                color: white;
                line-height: 14.4px;
                font-size: 12px;
                font-weight: normal;
                opacity: .6;
            }
        }
    }

    .myselfMessageBlock {
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-top: 8px;

        .myselfMessageTime {
            &__time {
                color: white;
                opacity: .6;
                font-family: 'SF UI Text', serif;
                font-size: 12px;
                line-height: 14.4px;
                font-weight: normal;
            }
        }

        .myselfMessageContent {
            max-width: 60%;
            width: fit-content;
            padding: 11.5px 16px;
            margin-left: 8px;
            background-color: #757575;
            border-radius: 4px;
            word-wrap: break-word;

            &__text {
                color: white;
                font-family: 'SF UI Text', serif;
                font-size: 14px;
                line-height: 16.8px;
                font-weight: normal;
            }
        }
    }

    &:hover {
        overflow-y: scroll;
        padding: 0 8px 0 16px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #515151;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #242424;
        border-radius: 6px;
        border: 3px solid #515151;
    }
}
</style>
