<template>
  <div class="chat"
       :class="{'active': chatIsActive,
                'attachments': attachmentBlockIsShowed}"
       v-if="showChat"
  >
    <div class="chat-header" @click="chatHeaderClick" @click.stop="chatIsActive = !chatIsActive">
      <div class="headerWrapper">
        <span class="headerWrapper__title"
              :class="{'headerWrapper__title_websocketNewMessage': getNewWebsocketMessage}">Чат матча</span>
        <svg :class="chatIsActive? 'active' : ''" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M4 10L8 6L12 10" stroke="white" stroke-linejoin="round"/>
        </svg>

        <div class="splitLine"></div>
      </div>
    </div>
    <div class="chat-body">
      <div class="filterBlock">
        <div class="filterBlockWrapper">
          <div class="filterBlockButton filterBlock Button__left"
               :class="{filterBlockButton_showed: filterIsActive, filterBlockButton_active:  filterByDialogsIsSelected}"
               @click="toggleFilterByDialogs"
          >
            <span class="filterBlockButton__text">Диалоги</span>
          </div>

          <div class="filterBlockButton filterBlockButton__right"
               :class="{filterBlockButton_showed: filterIsActive, filterBlockButton_active:  filterBySystemsIsSelected }"
               @click="toggleFilterBySystems"
          >
            <span class="filterBlockButton__text">Системные</span>
          </div>

          <div @click="toggleFilter" class="filterBlockButton__filter"
               :class="{filterBlockButton__filter_active: filterIsActive }">
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                    d="M8.32332 13.9673C8.32465 13.9567 8.32865 13.9487 8.32865 13.938V5.70865C8.32865 5.50198 8.16065 5.33398 7.95398 5.33398C7.74732 5.33398 7.57932 5.50198 7.57932 5.70865V13.938C7.57932 13.9487 7.58465 13.9567 7.58465 13.9673C6.31532 14.1473 5.33398 15.238 5.33398 16.5567C5.33398 17.8753 6.31532 18.966 7.58465 19.146C7.58332 19.1567 7.57932 19.1646 7.57932 19.1753V25.9087C7.57932 26.1153 7.74598 26.2833 7.95398 26.2833C8.16065 26.2833 8.32865 26.1153 8.32865 25.9087V19.1753C8.32865 19.1646 8.32332 19.1567 8.32332 19.146C9.59265 18.966 10.574 17.8753 10.574 16.5567C10.574 15.238 9.59265 14.1473 8.32332 13.9673ZM7.95532 18.4273C6.92465 18.4273 6.08465 17.5886 6.08465 16.5567C6.08465 15.526 6.92332 14.686 7.95532 14.686C8.98598 14.686 9.82598 15.5247 9.82598 16.5567C9.82598 17.5886 8.98732 18.4273 7.95532 18.4273Z"
                    fill="white"/>
                <path
                    d="M16.9268 18.83C16.9282 18.8193 16.9322 18.8113 16.9322 18.8007V5.70865C16.9322 5.50198 16.7655 5.33398 16.5575 5.33398C16.3508 5.33398 16.1828 5.50198 16.1828 5.70865V18.8007C16.1828 18.8113 16.1882 18.8193 16.1882 18.83C14.9188 19.01 13.9375 20.1006 13.9375 21.4193C13.9375 22.738 14.9188 23.8286 16.1882 24.0086C16.1868 24.0193 16.1828 24.0273 16.1828 24.038V25.9087C16.1828 26.1153 16.3495 26.2833 16.5575 26.2833C16.7642 26.2833 16.9322 26.1153 16.9322 25.9087V24.038C16.9322 24.0273 16.9268 24.0193 16.9268 24.0086C18.1962 23.8286 19.1775 22.738 19.1775 21.4193C19.1775 20.1006 18.1962 19.01 16.9268 18.83ZM16.5588 23.29C15.5282 23.29 14.6882 22.4513 14.6882 21.4193C14.6882 20.3886 15.5268 19.5487 16.5588 19.5487C17.5895 19.5487 18.4295 20.3873 18.4295 21.4193C18.4295 22.4513 17.5895 23.29 16.5588 23.29Z"
                    fill="white"/>
                <path
                    d="M27.783 10.946C27.783 9.62732 26.8016 8.53665 25.5323 8.35665C25.5336 8.34598 25.5376 8.33798 25.5376 8.32732V5.70865C25.5376 5.50198 25.371 5.33398 25.163 5.33398C24.9563 5.33398 24.7883 5.50198 24.7883 5.70865V8.32732C24.7883 8.33798 24.7936 8.34598 24.7936 8.35665C23.5243 8.53665 22.543 9.62732 22.543 10.946C22.543 12.2647 23.5243 13.3553 24.7936 13.5353C24.7923 13.546 24.7883 13.554 24.7883 13.5647V25.9087C24.7883 26.1153 24.955 26.2833 25.163 26.2833C25.3696 26.2833 25.5376 26.1153 25.5376 25.9087V13.5647C25.5376 13.554 25.5323 13.546 25.5323 13.5353C26.8016 13.3553 27.783 12.2647 27.783 10.946ZM25.1643 12.8166C24.1336 12.8166 23.2936 11.978 23.2936 10.946C23.2936 9.91532 24.1323 9.07532 25.1643 9.07532C26.195 9.07532 27.035 9.91398 27.035 10.946C27.035 11.9767 26.195 12.8166 25.1643 12.8166Z"
                    fill="white"/>
              </g>
            </svg>
          </div>

        </div>

      </div>

      <div class="chat-body-footer">
        <div class="wrapperBlock">
          <dialogs-and-systems-content :messages="filteredMessages"
                                       :attachments="chatAttachments"></dialogs-and-systems-content>
        </div>

        <div class="chat-reply_field" :class="{'chat-reply_field_disabled': getOnlySystemsSelected}">
          <input type="text" v-model="newMessage" v-on:keyup.enter="createMessage"
                 :disabled="getOnlySystemsSelected || disabledCreateMessage">

          <!-- TODO тут скрыта скрепка -->
          <div id="upload-container" v-if="false">
            <input type="file" id="file-input" name="files[]" @change="addFiles($event)" multiple>
            <label for="file-input" class="uploadImageButton">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.5672 0.568555L2.59979 15.536C0.842426 17.2933 0.842428 20.1426 2.59979 21.8999V21.8999C4.35715 23.6573 7.20639 23.6573 8.96375 21.8999L23.4594 7.40423C24.631 6.23265 24.631 4.33316 23.4594 3.16159V3.16159C22.2879 1.99001 20.3884 1.99001 19.2168 3.16159L4.83901 17.5394"
                    stroke="#B0B0B0"/>
              </svg>
            </label>
          </div>

          <div class="sendButton" @click="createMessage" :disabled="upload">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.8264 14.3712L6 4.93945L26 15.2032H16.5452L8.8264 14.3712ZM8.8264 17.5593L6 26.9395L26 16.6757H16.538L8.8264 17.5593Z"
                  fill="white"/>
            </svg>
          </div>
        </div>
      </div>

      <!-- TODO тут скрыты прикрепляемые файлы -->
      <div v-if="false"
           class="chat-attachedFiles"
           :class="{'chat-attachedFiles_disabled': getOnlySystemsSelected,
           'chat-attachedFiles_showed': attachmentBlockIsShowed}"
      >
        <template v-if="files.length > 0">
          <div class="imageFileContainer" :key="key" v-for="(file, key) in files">
            <div v-if="showFile(file, key)" class="selectedImage"
                 :class="filesProgressBar.some((element) => element === file.name) ? 'loading' : ''">
              <div class="removeButton" @click="removeFiles(file, key)">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10" r="10" fill="#3A3A3A"/>
                  <path d="M5.625 5.625L14.375 14.375M14.375 5.625L5.625 14.375" stroke="white"/>
                </svg>
              </div>
              <img :src="showFile(file, key)" alt="not found">
              <div class="attached-loader" v-if="filesProgressBar.some((element) => element === file.name)">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16Z"
                      fill="#B0B0B0"/>
                  <path
                      d="M16 30C16 31.1046 15.1011 32.0129 14.0051 31.8752C10.5183 31.4371 7.25176 29.8593 4.73081 27.358C1.73392 24.3846 0.0336498 20.3472 0.000493463 16.1257C-0.0326628 11.9041 1.60398 7.84051 4.55379 4.82036C7.50361 1.80021 11.5275 0.0682846 15.7487 0.00197383C19.9699 -0.0643369 24.0462 1.54034 27.0894 4.46634C30.1326 7.39235 31.8961 11.4025 31.9956 15.623C32.095 19.8436 30.5224 23.9324 27.6204 26.9985C25.1793 29.5777 21.9639 31.2573 18.4925 31.8047C17.4014 31.9768 16.4744 31.0971 16.4397 29.9931C16.4051 28.8891 17.2786 27.9828 18.3616 27.7654C20.776 27.2808 23.0004 26.0608 24.7153 24.2489C26.8918 21.9493 28.0713 18.8827 27.9967 15.7173C27.9221 12.5519 26.5995 9.54426 24.3171 7.34976C22.0346 5.15526 18.9774 3.95175 15.8115 4.00148C12.6456 4.05121 9.62771 5.35016 7.41535 7.61527C5.20298 9.88038 3.9755 12.9281 4.00037 16.0942C4.02524 19.2604 5.30044 22.2885 7.5481 24.5185C9.3191 26.2757 11.5807 27.4252 14.0092 27.8338C15.0984 28.017 16 28.8954 16 30Z"
                      fill="url(#paint0_angular)"/>
                  <defs>
                    <radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(16 16) rotate(90) scale(16)">
                      <stop stop-color="#E2FF31"/>
                      <stop offset="0.578125" stop-color="#E2FF31"/>
                      <stop offset="1" stop-color="#E2FF31" stop-opacity="0"/>
                    </radialGradient>
                  </defs>
                </svg>

              </div>
            </div>

            <svg v-else viewBox="0 0 78 78" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M45.0208 42.232C45.1586 42.2563 45.2964 42.2681 45.4324 42.2681C46.5568 42.2681 47.5492 41.4638 47.7518 40.3202C47.7761 40.1823 47.7879 40.0445 47.7879 39.9089C47.7879 38.7848 46.9833 37.7923 45.8397 37.5898C45.7015 37.5652 45.5637 37.5537 45.428 37.5537C44.3037 37.5537 43.3112 38.3583 43.1084 39.5019C43.0841 39.6398 43.0723 39.7776 43.0723 39.9135C43.0723 41.037 43.8771 42.0294 45.0208 42.232ZM43.9978 39.6584C44.1214 38.9528 44.7371 38.4563 45.428 38.4563C45.512 38.4563 45.5967 38.4634 45.6825 38.4787H45.6829C46.3885 38.6022 46.885 39.2179 46.885 39.9082C46.885 39.9919 46.8779 40.0769 46.8626 40.1631C46.7391 40.8684 46.1234 41.3646 45.4327 41.3649C45.349 41.3649 45.2638 41.3577 45.1776 41.3425C44.4723 41.219 43.9758 40.6033 43.9758 39.9129C43.9754 39.8289 43.9826 39.744 43.9978 39.6584Z"
                  fill="#757575"/>
              <path
                  d="M25.0044 29.2863L25.0072 29.3227L28.0309 46.3965L27.6268 48.6888C27.6062 48.8058 27.6333 48.929 27.7014 49.0264C27.7696 49.1237 27.876 49.1916 27.9929 49.2121L49.4445 52.9937C49.69 53.037 49.9242 52.873 49.9678 52.6275L52.9937 35.4703C53.0143 35.3534 52.9872 35.2302 52.9191 35.1328C52.8509 35.0354 52.7445 34.9676 52.6275 34.947L48.9156 34.2925L47.3525 25.3737V25.3733V25.373L47.3426 25.3382L47.3277 25.2853L47.3118 25.2542L47.2872 25.2063L47.2592 25.1708L47.2331 25.1381L47.1936 25.1042L47.1671 25.0818L47.1254 25.0582L47.0915 25.0392L47.0508 25.0255L47.0088 25.0115L46.9668 25.0062L46.9207 25L46.8725 25.0034L46.8296 25.0065H46.8292H46.8289L25.373 28.7994L25.35 28.8059L25.285 28.8243L25.2265 28.8544L25.2063 28.865L25.1829 28.8837C25.1207 28.93 25.0706 28.9904 25.0392 29.0607L25.0342 29.0744C25.0143 29.1236 25.0016 29.1764 25 29.2315L25.0044 29.2863ZM31.4635 32.133L48.3736 35.1141C48.4231 35.1322 48.4754 35.1418 48.5295 35.1415L52.0258 35.7578L49.7836 48.4698L47.7358 45.5459C47.5927 45.3415 47.3112 45.292 47.1071 45.4351L44.0836 47.5517L37.4889 38.1357C37.3458 37.9313 37.0642 37.8818 36.8601 38.0249L29.518 43.1646L31.4635 32.133ZM49.1567 52.0255L28.5943 48.4007L28.93 46.4982V46.4979L29.2962 44.4224L37.0082 39.0236L43.603 48.4396C43.7461 48.644 44.0276 48.6935 44.2317 48.5503L47.2552 46.4338L49.5301 49.6822L49.563 49.7205L49.1567 52.0255ZM31.176 31.1651C31.0571 31.1443 30.9333 31.1723 30.835 31.2423L30.8344 31.2426C30.7376 31.3116 30.6729 31.4146 30.6521 31.5316L28.4882 43.8011L25.975 29.6101L46.5411 25.975L47.9695 34.1257L31.176 31.1651Z"
                  fill="#757575"/>
              <path
                  d="M4 1H74V-1H4V1ZM77 4V74H79V4H77ZM74 77H4V79H74V77ZM1 74V4H-1V74H1ZM4 77C2.34315 77 1 75.6569 1 74H-1C-1 76.7614 1.23858 79 4 79V77ZM77 74C77 75.6569 75.6569 77 74 77V79C76.7614 79 79 76.7614 79 74H77ZM74 1C75.6569 1 77 2.34315 77 4H79C79 1.23858 76.7614 -1 74 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
                  fill="#757575"/>
            </svg>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>

<script>

import DialogsAndSystemsContent from "./DialogsAndSystemsContent";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {API} from "@/Vuex/axios-config";
import {ActiveEcho} from "@/main";
import newMessage from '@/sounds/newMessage.mp3';

export default {
  name: "chat",
  components: {
    DialogsAndSystemsContent,
  },
  data() {
    return {
      chatIsActive: true,
      filterIsActive: true,
      filterByDialogsIsSelected: true,
      filterBySystemsIsSelected: true,
      files: [],
      filesLoaded: [],
      newMessage: '',
      upload: false,
      filesProgressBar: [],
      showChat: false,
      echo: null,
      chatAttachments: [],
      disabledCreateMessage: false
    }
  },
  computed: {
    ...mapGetters({
      messages: 'MESSAGES',
      chat: 'GET_CHAT',
      match: 'MATCH',
      newMessages: 'GET_WEB_SOCKETS_NEW_MESSAGES',
    }),
    filteredMessages() {
      return this.messages.filter(function (message) {
        if (this.filterBySystemsIsSelected && this.filterByDialogsIsSelected) {
          return true;
        } else if (this.filterByDialogsIsSelected && message.attributes.type_message === 'simple') {
          return true;
        } else if (this.filterBySystemsIsSelected && message.attributes.type_message === 'system') {
          return true;
        } else {
          return false;
        }
      }, this);
    },
    getOnlyDialogsSelected() {
      return this.filterByDialogsIsSelected && !this.filterBySystemsIsSelected
    },

    getOnlySystemsSelected() {
      return this.filterBySystemsIsSelected && !this.filterByDialogsIsSelected
    },

    getDialogsAndSystemsSelected() {
      return this.filterByDialogsIsSelected && this.filterBySystemsIsSelected
    },

    getFirstAttachedImage() {
      return this.firstAttachedImage
    },

    attachmentBlockIsShowed() {
      return this.files.length > 0;
    },

    getNewWebsocketMessage() {
      return this.newMessages
    }
  },
  methods: {
    ...mapActions([
      'SET_WEB_SOCKETS_NEW_MESSAGES_ACTION'
    ]),

    chatHeaderClick() {
      this.SET_WEB_SOCKETS_NEW_MESSAGES_ACTION(false)
    },

    toggleFilter() {
      this.filterIsActive = !this.filterIsActive
    },
    toggleFilterByDialogs() {
      this.filterByDialogsIsSelected = !this.filterByDialogsIsSelected;
      if (!this.filterByDialogsIsSelected && !this.filterBySystemsIsSelected) {
        this.filterBySystemsIsSelected = !this.filterBySystemsIsSelected;
      }
    },
    toggleFilterBySystems() {
      this.filterBySystemsIsSelected = !this.filterBySystemsIsSelected;
      if (!this.filterByDialogsIsSelected && !this.filterBySystemsIsSelected) {
        this.filterByDialogsIsSelected = !this.filterByDialogsIsSelected;
      }
    },
    updateProgressBarValue(progressEvent, key) {
      if (progressEvent.loaded !== progressEvent.total) {
        console.log(progressEvent, progressEvent.loaded !== progressEvent.total, 'IF')
        this.filesProgressBar.push(key);
        console.log(this.filesProgressBar)
      } else {
        console.log(progressEvent, progressEvent.loaded !== progressEvent.total, 'ELSE')
        this.filesProgressBar = this.filesProgressBar.filter((element) => element !== key);
        console.log(this.filesProgressBar)
      }
    },
    addFiles(event) {
      if (this.files.length < 3) {
        Array.prototype.forEach.call(event.target.files, function (file) {
          this.files.push(file);
          let formData = new FormData();
          formData.append('file', file)
          formData.append('tag', 'attach')
          API({
            url: `/files/upload`,
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
            onUploadProgress: (progressEvent) => {
              this.updateProgressBarValue(progressEvent, file.name)
            },
          }, this).then(res => {
            this.filesLoaded.push(res.data.data);
          })
        }, this)
      }
    },
    removeFiles(file, key) {
      if (this.files.length === 1) {
        this.files = [];
      } else {
        this.files.splice(key, 1);
      }

      let fileLoaded = this.filesLoaded[key];
      API({
        url: fileLoaded.links.self,
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
        },
      })
      if (this.filesLoaded.length === 1) {
        this.filesLoaded = [];
      } else {
        this.filesLoaded.splice(key, 1);

      }
    },
    showFile(file) {
      if (file) {
        return URL.createObjectURL(file);
      }
    },
    ...mapActions({
      getChat: 'getChatWithMessages',
      CREATE_MESSAGE: 'CREATE_MESSAGE'
    }),
    ...mapMutations([
      'setOnlineUsers',
      'addOnlineUser',
      'removeOnlineUser',
      'pushMessage'
    ]),
    createMessage() {
      this.disabledCreateMessage = true;
      this.CREATE_MESSAGE({files: this.filesLoaded, message: this.newMessage}).then(() => {
        this.chatAttachments = this.chatAttachments.concat(this.filesLoaded);
        this.disabledCreateMessage = false;
        this.newMessage = '';
        this.files = [];
        this.filesLoaded = [];
      })
    },
    chatChannel() {
      this.echo = ActiveEcho.join(`matches.${this.match.data.id}.chats.${this.match.data.attributes.chat_id}`)
          .here((users) => {
            this.showChat = !this.showChat;
            this.getChat();
            this.setOnlineUsers(users);
          })
          .leaving((user) => {
            this.removeOnlineUser(user)
          })
          .joining((user) => {
            this.addOnlineUser(user)
          })
          .listen('.NewMessage', async (event) => {
            this.pushMessage(event);

            // После получения нового сообщения пытаюсь дать звуковой сигнал.
            // Звук не сработает, если пользователь не взаимодействовал со страницей (политика Chrome)

            if (!this.chatIsActive) {
              try {
                this.SET_WEB_SOCKETS_NEW_MESSAGES_ACTION(true)

                const audio = new Audio(newMessage);
                await audio.play();

              } catch (e) {
                console.log(e)
              }
            }
          });
    },
    hasToken() {
      return !!localStorage.getItem('userAccessToken');
    }
  },
  mounted() {
    this.chatIsActive = window.innerWidth > 525;


    if (this.hasToken()) {
      this.chatChannel();
    }
  },
  beforeDestroy() {
    if (this.showChat) {
      this.showChat = false;
      ActiveEcho.leave(`matches.${this.match.data.id}.chats.${this.match.data.attributes.chat_id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins";

.filterBlock {
  background-color: #272727;

  .filterBlockWrapper {
    min-height: 34px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;


    .filterBlockButton {
      opacity: 0;
      transform: translateY(-100%);
      cursor: pointer;
      background-color: #272727;
      border-radius: 30px;
      transition: transform .3s ease, opacity .3s ease, background-color .3s ease, filter .3s ease;
      padding: 9px 14px;

      &__filter {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 0;

        svg {
          g {
            path {
              fill: white;
              opacity: .4;
              transition: all .2s ease-in-out;
            }
          }

        }

        &:hover {
          svg {
            g {
              path {
                fill: var(--tournament-color);
                transition: all .2s ease-in-out;
                opacity: 1;
              }
            }
          }
        }

        &_active {
          svg {
            g {
              path {
                fill: var(--tournament-color);
                transition: all .2s ease-in-out;
                opacity: 1;
              }
            }
          }
        }
      }

      &__right {
        margin-left: 4px;
      }

      &__text {
        transition: all .3s ease;
        font-family: 'SF UI Text', serif;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        opacity: .5;
        color: white;
        text-align: center;
      }

      &_active {
        transition: background-color .2s ease, transform .3s ease, opacity .2s ease;
        background-color: var(--tournament-color);

        .filterBlockButton__text {
          transition: color .2s ease, opacity .2s ease;
          color: white;
          opacity: 1;
        }
      }

      &_showed {
        opacity: 1;
        transform: translateY(0);
        transition: transform .3s ease-in-out, opacity .3s ease-in-out, background-color .3s ease-in-out, filter .3s ease-in-out;
      }
    }
  }
}

@media (hover: hover) {
  .filterBlock {
    .filterBlockButton {
      &:hover {
        transition: background-color .2s ease-in-out;
        filter: brightness(.8);

        .filterBlockButton__text {
          transition: color .2s ease-in-out, opacity .2s ease-in-out;
          color: white;
          opacity: 1;
        }
      }
    }
  }
}

</style>
